body, h1, h2, h3, h4, h5, h6, p, ul, ol {
    margin: 0;
    padding: 0;
}
  
/* Set font family and size */
body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
}
  
/* Style for the entire App */
.App {
    width: 100%;
    max-width: 95%; /* Adjust as needed */
    margin: 0 auto;
    padding: 20px;
}
  
/* Style for the main heading */
h1 {
    font-size: 30px;
    margin-bottom: 20px;
    color: #F9B120;
    text-align: center;
}

.main-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; /* Divide into three equal parts */
    gap: 20px; /* Space between columns */
    padding: 20px;
}

.main-container button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #f9c74f;
    color: #000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.main-container button:hover {
    background-color: #f4ebb8;
}

.content-pane {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content-pane .category-selector-container {
    width: 80%;
    margin: 0 auto; /* Center the selector */
}

.details-pane{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.results-pane{
    width: 80%;
}