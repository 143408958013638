.category-selector-container {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

select {
    width: 100%;
    padding: 10px;
    border: 1px solid #FC8510;
    border-radius: 5px;
    box-sizing: border-box;
}

select:focus, select:hover {
    border-color: #F4EBB8;
}

select option {
    background-color: #fff;
    color: #333;
}

select option:checked, select option:hover {
    background-color: #F4EBB8;
    color: #333;
}
