label {
  display: block;
  margin-bottom: 5px;
  margin-top: 5px;
  align-self: center;
}

input[type="text"],
input[type="number"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #fc8510;
  border-radius: 5px;
  box-sizing: border-box;
}
input[type="text"]:hover,
input[type="number"]:hover {
  border-color: #f4ebb8; /* Change border color on hover */
}

.error {
  color: red;
  margin-top: 10px;
}

/* Style for the ResetForm component */
.reset button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #f9c74f;
  color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.reset button:hover {
  background-color: #f4ebb8;
}

/* Custom Tooltip Styles */
.react-tooltip {
  max-width: 250px;
  white-space: normal;
  word-wrap: break-word;
  padding: 10px;
  font-size: 12px;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .react-tooltip {
    max-width: 150px;
    font-size: 10px;
  }
}

/* Tooltip related styles */
.foodtip1 {
  display: flex;
  align-items: left;
}

.input-wrapper .tooltip2 {
  margin-left: 10px;
  margin-bottom: 5px;
}

/* non - dairy bev tooltip css */

.form-group {
  align-items: center;
  margin-bottom: 10px;
}

.form-group label {
  margin-right: 10px;
  align-self: center;
}

.form-group .tooltip2 {
  margin-left: 10px;
  margin-bottom: 5px;
}

.non-dairy-fvnl {
  display: flex;
  align-items: left;
}

.content-wrapper{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.main-form-container {
  width: 100%;
}